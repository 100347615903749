import { Component } from '@angular/core';
import { AppService } from './services/app.service';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'pro-dashboard-angular';
  currentUser: any;
  constructor(private appService: AppService, private authService: AuthService) {
    this.authService.currentUser.subscribe(x => this.currentUser = x);
  }

  getClasses() {
    const classes = {
      'pinned-sidebar': this.appService.getSidebarStat().isSidebarPinned,
      'toggeled-sidebar': this.appService.getSidebarStat().isSidebarToggeled
    }
    return classes;
  }

  toggleSidebar() {
    this.appService.toggleSidebar();
  }

}
