<div>
    <div class="row">
        <div class="col-md-6 mb-4 ">
            <div class="card border-0 rounded-0">
                <div class="card-title mb-1 p-3">
                    <h5>Edit Commodity</h5>
                </div>
                <div class="card-body">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
                        <div class="form-group row">
                            <div class="col-md-9">
                                <label>Category Label</label>
                                <input type="text" formControlName="label" class="form-control  rounded-0"
                                    [ngClass]="{ 'is-invalid': submitted && f.label.errors }">
                                <div *ngIf="submitted && f.label.errors" class="invalid-feedback">
                                    <div *ngIf="f.label.errors.required">Label is required</div>
                                </div>
                            </div>

                            <div class="col-md-3 text-center">
                                <label class="text-center" for="uploadIcon">
                                    <div *ngIf="!iconSrc" class="rounded img-thumbnail px-2 py-4">
                                        <i class="fa fa-image"></i> <span class="small"> Upload Icon</span>
                                    </div>
                                    <img [src]="iconSrc" *ngIf="iconSrc" style="width:100px">
                                    <input type="file" id="uploadIcon" class="d-none" (change)="onIconChange($event)">
                                </label>
                            </div>
                        </div>
                        <div formArrayName="languages">
                            <div class="row" *ngFor="let language of languages().controls; let i=index;"
                                [formGroupName]="i">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Language</label>
                                        <select formControlName="key" class="form-control rounded-0">
                                            <option value="">Select</option>
                                            <option value="KAN">ಕನ್ನಡ</option>
                                            <option value="EN">English</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Label</label>
                                    <input type="text" formControlName="value" class="form-control  rounded-0"
                                        [ngClass]="{ 'is-invalid': submitted && f.label.errors }">
                                    <div *ngIf="submitted && f.label.errors" class="invalid-feedback">
                                        <div *ngIf="f.label.errors.required">Label is required</div>
                                    </div>
                                </div>
                                <div class="col-md-2 mt-4 pt-1">
                                    <div class="btn-group">
                                        <button type="button" (click)="addLanguage()" class="btn btn-primary btn-sm"><i
                                                class="fa fa-plus"></i></button>
                                        <button *ngIf="i" type="button" (click)="removeLanguage(i)"
                                            class="btn btn-danger btn-sm"><i class="fa fa-trash"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Description</label>
                            <textarea formControlName="description" class="form-control rounded-0" rows="3"
                                [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                            <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                <div *ngIf="f.description.errors.required">Description is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Display Order</label>
                            <input type="text" formControlName="disOrd" class="form-control  rounded-0">
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-lightning rounded-0 mb-2 mr-2">Save</button>
                            <a class="btn btn-lightning rounded-0 mb-2 mr-2" (click)="gotoCommodities()">Cancel</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>