import { decimalDigest } from '@angular/compiler/src/i18n/digest';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestService } from 'src/app/services/rest.service';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  @ViewChild(BsDatepickerDirective, { static: false }) datepicker: BsDatepickerDirective;


  constructor(private rest: RestService, private router: Router, private toastr: ToastrService) { 
    
  }
  reports: any = [];
  categories: any = [];
  commodities:any = [];
  vendors: any = [];
  categoryId: any;
  category:any = [];
  dateTime: any;
  vendorId ='';
  commodity ='';
  dealStatus ='';
  locationType ='';
  stat = '';  
  imageUrl = '';
  showModel = 'none';

  filters = {
    order: [
      {
        column: "startTime",
        order: "DESC"
      }
    ],
    pageable: {
      length: 100,
      start: 0
    }    
  };

  ngOnInit(): void {
    this.getCategories();
    this.getVendors();
  }

  getDeals(): void {
    
    if (!!this.dateTime)
    Object.assign(this.filters, { startDateTime: this.dateTime[0].setHours(6,0,0,0)});

    if (!!this.dateTime)
    Object.assign(this.filters, { endDateTime: this.dateTime[1].setHours(23,29,59,0)});

    if (!!this.category) 
    Object.assign(this.filters, {category:this.category});

    if (!!this.commodity)
    Object.assign(this.filters, {commodity:this.commodity});
    
    if (this.vendorId != "") 
    Object.assign(this.filters, {vendorId:this.vendorId});
    
    if(this.stat)
    Object.assign(this.filters, {stat:this.stat});

    if(this.locationType)
    Object.assign(this.filters, {locationType:this.locationType});

    if(this.dealStatus)
    Object.assign(this.filters, {dealStatus:this.dealStatus});


    this.rest.reports('deals/report', this.filters).subscribe((res: any) => {
      if (res.status_code == 200) {
      this.reports = res.result;
      } 
    },  error => {
      this.toastr.error(error.error, 'Error');
    });
  }

  reportFilters(): void {
    this.getDeals();
  }

  getCategories(): void {
    this.rest.getAll('categories?start=0&length=100').subscribe((res: any) => {
      this.categories = res.result;
      
      for(var i = 0; i < this.categories.data.length; i ++) {
        if(this.categories.data[i].commodities.length) {
          for(var j = 0; j < this.categories.data[i].commodities.length; j++) {
            this.commodities.push(this.categories.data[i].commodities[j]);
          }
        }
      }
    });
  }

  getCommoditiesByCategory(): void {
    this.getDeals();
  }
   
 
  getVendors(): void {
    this.rest.getAll('vendors?start=0&length=10000').subscribe((res: any) => {
      this.vendors = res.result;
    });
  }

  resetFilters(): void {
    this.category ='';
    this.vendorId ='';
    this.commodity ='';
    this.dealStatus ='';
    this.locationType ='';
    this.stat = '';
    this.dateTime = '';
    this.filters = {
      order: [
        {
          column: "startTime",
          order: "DESC"
        }
      ],
      pageable: {
        length: 100,
        start: 0
      }    
    };
    
    this.getDeals();

  }
  
  convetToDate(string: string)  {
    return new Date(string);
  }

  imagePopup(url: string) {
    this.imageUrl = url;
    this.showModel = 'block';
  }
  
  closePopup()
  {
    this.showModel = 'none';
  }
}
