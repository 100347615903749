import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { concatMap, tap } from "rxjs/operators";
import { RestService } from "src/app/services/rest.service";

@Component({
  selector: "app-edit-category",
  templateUrl: "./edit-category.component.html",
  styleUrls: ["./edit-category.component.scss"],
})
export class EditCategoryComponent implements OnInit {
  categoryId: any;
  category: any;
  form = new FormGroup({});
  submitted = false;
  imageData: any;
  iconData: any;
  imageSrc: any;
  iconSrc: any;
  sources = [];
  

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private restService: RestService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.categoryId = this.activatedRoute.snapshot.paramMap.get("id");
  }

  ngOnInit(): void {
    this.getCategory();

    this.form = this.formBuilder.group({
      id: [""],
      label: ["", [Validators.required, Validators.minLength(2)]],
      description: ["", [Validators.required]],
      disOrd: [""],
      languages: this.formBuilder.array([]),
    });
  }

  
  languages(): FormArray {
    return this.form.get("languages") as FormArray
  }

  addLanguage() {
    this.languages().push(this.newLanguage());
  }

  newLanguage(): FormGroup {
    return this.formBuilder.group({
      key: '',
      value: ''
    })
  }

  removeLanguage(i: number) {
    this.languages().removeAt(i);
  }


  get f() {
    return this.form.controls;
  }


  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.restService.update("categories/", this.form.value).subscribe(
      (res) => {
        if (res.status_code == 200) {
          this.updateFile();
          this.updateIcon();
          if (!this.imageData && !this.iconData) {
            this.router.navigate(["categories"]);
          }
          this.toastr.success("Category updated successfully.", "Success");
        } else {
          this.toastr.error(res.status_message, 'Error');
        }
      },
      (error) => {
        this.toastr.error(error.error, "Error");
      }
    );

  }

  async updateFile() {
    if (this.imageData) {
      const formData = new FormData();
      formData.append("files", this.imageData);
      await this.restService
      .upload(`categories/${this.categoryId}/photo`, formData)
      .subscribe(
        (res) => {
          if (!this.iconData) {
            this.router.navigate(["categories"]);
          }
        },
        (error) => {
          this.toastr.error(error.error, "Error");
        }
        );
      }
  }
  
  async updateIcon() {
    if (this.iconData) {
      const formData = new FormData();
      formData.append("files", this.iconData);
      await this.restService
      .upload(`categories/${this.categoryId}/icon`, formData)
      .subscribe(
        (res) => {
          this.router.navigate(["categories"]);
        },
        (error) => {
          this.toastr.error(error.error, "Error");
        }
        );
      }
    }

  onIconChange(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      this.iconData = event.target.files[0];
      reader.readAsDataURL(this.iconData);
      reader.onload = () => {
        this.iconSrc = reader.result;
      };
    }
  }

  onImageChange(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      this.imageData = event.target.files[0];
      reader.readAsDataURL(this.imageData);
      reader.onload = () => {
        this.imageSrc = reader.result;
      };
    }
  }

  getCategory(): void {
    this.restService.get("categories/", this.categoryId).subscribe(
      (res) => {
        this.category = res.result;
        this.form.patchValue({
          id: this.category.id,
          label: this.category.label,
          description: this.category.description,
          disOrd: this.category.disOrd,
          languages: []
        });
        this.category.languages2.forEach((language: any) => {
          this.languages().push(
            this.formBuilder.group(language)
          );  
        });
        this.imageSrc = this.category.imageUrl;
        this.iconSrc = this.category.iconUrl;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
