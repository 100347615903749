import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit {

  form = new FormGroup({});
  submitted = false;
  languagesArr = [];
  categoryId = null;
  imageData: any;
  iconData: any;
  imageSrc: any;
  iconSrc: any;

  constructor(private formBuilder: FormBuilder, private router: Router, private restService: RestService, private toastr: ToastrService) {

  }

  ngOnInit(): void {
    this.getLanguages();

    this.form = this.formBuilder.group({
      label: ['', [Validators.required, Validators.minLength(2)]],
      description: ['', [Validators.required]],
      disOrd: [''],
      languages: this.formBuilder.array([this.formBuilder.group({
        key: '',
        value: ''
      })]),
    });
    this.newLanguage();
  }


  languages(): FormArray {
    return this.form.get("languages") as FormArray
  }

  addLanguage() {
    this.languages().push(this.newLanguage());
  }

  newLanguage(): FormGroup {
    return this.formBuilder.group({
      key: '',
      value: ''
    })
  }

  removeLanguage(i: number) {
    this.languages().removeAt(i);
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.restService.add('categories', this.form.value)
      .subscribe(
        res => {
        if (res.status_code == 200) {
          this.categoryId = res.result.id;          
          if (this.imageData) this.uploadImage("photo");
          if (this.iconData) this.uploadIcon("icon");
          this.router.navigate(['categories']);
          this.toastr.success('Category added successfully.', 'Success');  
        } else {
          this.toastr.error(res.status_message, 'Error');
        }        
        },
        error => {
          console.log(error)
        });
  }

  getLanguages(): void {
    this.restService.getLanguages('public/language').subscribe((res: any) => {
      this.languagesArr = res.result;
    });
  }

  
  uploadImage(type: string): void {
    const formData = new FormData();
    formData.append("files", this.imageData);
    this.restService
      .upload(`categories/${this.categoryId}/${type}`, formData)
      .subscribe(
        (res) => {
          if (res.status_code == 200) {
            console.log(`${type} updated`);
          } else {
            this.toastr.error(res.status_message, 'Error');
          }  
        },
        (error) => {
          this.toastr.error(error.error, "Error");
        }
      );
  }
  
  uploadIcon(type: string): void {
    const formData = new FormData();
    formData.append("files", this.iconData);
    this.restService
      .upload(`categories/${this.categoryId}/${type}`, formData)
      .subscribe(
        (res) => {
          if (res.status_code == 200) {
            console.log(`${type} updated`);
          } else {
            this.toastr.error(res.status_message, 'Error');
          }  
        },
        (error) => {
          this.toastr.error(error.error, "Error");
        }
      );
  }

  onIconChange(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      this.iconData = event.target.files[0];
      reader.readAsDataURL(this.iconData);
      reader.onload = () => {
        this.iconSrc = reader.result;
      };
    }
  }

  onImageChange(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      this.imageData = event.target.files[0];
      reader.readAsDataURL(this.imageData);
      reader.onload = () => {
        this.imageSrc = reader.result;
      };
    }
  }


}
