<div>
    <div class="row mb-4">

        <div class="col-md-12">
            <div class="card border-0 rounded-0">
                <div class="card-title mb-1 p-3">
                    <div class="row">
                        <div class="col-md-10">
                            <h5>Vendors</h5>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive-md">
                        <table class="table table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Phone</th>
                                    <th scope="col">Language</th>
                                    <th scope="col">Wallet</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let vendor of vendors;">
                                    <td scope="row">{{ vendor.id }}</td>
                                    <td>{{ vendor.name }}</td>
                                    <td>{{ vendor.phone }}</td>
                                    <td>{{ vendor.secondLanguage }}</td>
                                    <td>{{ vendor.wallet }}</td>
                                    <td>{{ vendor.venorStatus ? 'Active' : 'Inactive' }}</td>
                                    <td>
                                        <a class="btn btn-sm mr-2 btn-secondary"
                                            [ngClass]="{'btn-success': vendor.venorStatus == true}"
                                            (click)="updateStatus(vendor.id, vendor.venorStatus)">
                                            {{ !vendor.venorStatus ? 'Activate' : 'Deactivate' }}
                                        </a>
                                    </td>
                                </tr>
                                <tr *ngIf="vendors.length == 0">
                                    <td colspan="7" align="center">No Records</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>