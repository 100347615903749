import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.scss']
})
export class UnitsComponent implements OnInit {

  constructor(private rest: RestService, private router: Router) { }
  units: any = [];

  ngOnInit(): void {
    this.getUnits();
  }

  getUnits(): void {
    this.rest.getAll('units?start=0&length=100').subscribe((res: any) => {
      this.units = res.result;
    });
  }

  addUnit(): void {
    this.router.navigate(['/units/add']);
  }

  
  onEdit(unitId: number): void {
    this.router.navigate(['/units/edit/' + unitId])
  }

  onDelete(unitId: number): void {

  }


}
