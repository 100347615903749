import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CategoriesComponent } from './pages/categories/categories.component';
import { AddCategoryComponent } from './pages/categories/add-category/add-category.component';
import { EditCategoryComponent } from './pages/categories/edit-category/edit-category.component';
import { UnitsComponent } from './pages/units/units.component';
import { AddUnitComponent } from './pages/units/add-unit/add-unit.component';
import { DealsComponent } from './pages/deals/deals.component';
import { VendorsComponent } from './pages/vendors/vendors.component';
import { CommoditiesComponent } from './pages/commodities/commodities.component';
import { EditUnitComponent } from './pages/units/edit-unit/edit-unit.component';
import { AddCommodityComponent } from './pages/commodities/add-commodity/add-commodity.component';
import { EditCommodityComponent } from './pages/commodities/edit-commodity/edit-commodity.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    LoginComponent,
    CategoriesComponent,
    AddCategoryComponent,
    EditCategoryComponent,
    UnitsComponent,
    AddUnitComponent,
    DealsComponent,
    VendorsComponent,
    CommoditiesComponent,
    EditUnitComponent,
    AddCommodityComponent,
    EditCommodityComponent,
    ReportsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    CollapseModule.forRoot(),
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BsDatepickerModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
