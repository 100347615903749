import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss']
})
export class VendorsComponent implements OnInit {


  constructor(private restService: RestService, private router: Router, private toastr: ToastrService) { }
  vendors: any = [];

  ngOnInit(): void {
    this.getVendors();
  }

  getVendors(): void {
    this.restService.getAll('vendors?start=0&length=100').subscribe((res: any) => {
      this.vendors = res.result;
    });
  }

  addVendor(): void {
    this.router.navigate(['/vendors/add']);
  }

  updateStatus(vendorId: number, status: number): void {
    status = !status ? 1 : 0;
    this.restService
      .get(`vendors/${vendorId}/status/`, status)
      .subscribe(
        (res) => {
          let message = status ? 'Activated' : 'Deactivated';
          this.toastr.success(`Vendor ${message} successfully.`, 'Success');          
          this.getVendors();
        },
        (error) => {
          this.toastr.error(error.error, "Error");
        }
      );
  }



}
