import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-commodities',
  templateUrl: './commodities.component.html',
  styleUrls: ['./commodities.component.scss']
})
export class CommoditiesComponent implements OnInit {

  categories: any = [];
  commodities: any = [];
  category: any;
  categoryId: any;

  constructor(private rest: RestService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.categoryId = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.getCategories();
    
    this.getCommodities(this.categoryId)
  }

  getCategories(): void {
    this.rest.getAll('categories?start=0&length=100').subscribe((res: any) => {
      this.categories = res.result;
    });
  }

  getCommodities(categoryId: any): void {
    this.commodities = [];
    this.rest.getAll('categories/' + categoryId + '/commodities?start=0&length=100').subscribe((res: any) => {
      this.commodities = res.result;
    });
  }

  addCommodity(): void {
    this.router.navigate([`/commodities/${this.categoryId}/add`]);
  }

  getCommoditiesByCategory(): void {
    this.getCommodities(this.category);
  }
  
  onEdit(commodityId: number): void {
    this.router.navigate([`/commodities/${this.categoryId}/edit/${commodityId}`])
  }
  
  onDelete(commodityId: number): void {

  }

}
