<aside class="sidebar shadow-sm">
  <div class="sidebar-search pt-1 pb-1 pl-3 pr-3">
    <input type="search" class="form-control rounded-pill" name="search" placeholder="Search ...">
  </div>
  <nav class="sidebar-menu pt-3 pb-3">
    <ul class="menu-items">
      <li>
        <a href="#" routerLink="/" routerLinkActive="active">
          <i class="fa fa-tv"></i>
          <span>Dashboard</span>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/categories" routerLinkActive="active">
          <i class="fa fa-list-alt"></i>
          <span>Categories</span>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/commodities/0" routerLinkActive="active">
          <i class="fa fa-list-alt"></i>
          <span>Commodities</span>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/vendors" routerLinkActive="active">
          <i class="fa fa-user-plus"></i>
          <span>Vendors</span>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/units" routerLinkActive="active">
          <i class="fa fa-percentage"></i>
          <span>Units</span>
        </a>
      </li>

      <li>
        <a href="#" routerLink="/deals" routerLinkActive="active">
          <i class="fa fa-tag"></i>
          <span>Deals</span>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/reports" routerLinkActive="active">
          <i class="fa fa-chart-bar"></i>
          <span>Reports</span>
        </a>
      </li>
      <li>
        <a href="#" (click)="logout()">
          <i class="fa fa-sign-out-alt"></i>
          <span>Logout</span>
        </a>
      </li>
    </ul>
  </nav>
</aside>