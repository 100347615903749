<div class="container-fluid">
  <div class="row pt-4">
    <div class="col-md-12 text-center mb-2">
      <small> © 2019 made with <i class="fa fa-heart text-danger" aria-hidden="true"></i> by <span
          class="text-secondary font-weight-bold">Mohamed
          Azouaoui</span></small>
    </div>
    <div class="col-md-12 text-center mb-3">
      <a href="https://github.com/azouaoui-med" target="_blank"
        class="btn btn-sm bg-light text-dark shadow-sm rounded-circle text-light m-2"><i class="fab fa-github"></i></a>
      <a href="https://twitter.com/azouaoui_med" target="_blank"
        class="btn btn-sm bg-light text-dark shadow-sm rounded-circle text-light m-2"><i class="fab fa-twitter"></i></a>
      <a href="https://www.instagram.com/azouaoui_med/" target="_blank"
        class="btn btn-sm bg-light text-dark shadow-sm rounded-circle text-light m-2"><i
          class="fab fa-instagram"></i></a>
      <a href="https://www.linkedin.com/in/mohamed-azouaoui/" target="_blank"
        class="btn btn-sm bg-light text-dark shadow-sm rounded-circle text-light m-2"><i
          class="fab fa-linkedin-in"></i></a>
    </div>
    <div class="col-md-12 text-center">
      <iframe
        src="https://ghbtns.com/github-btn.html?user=azouaoui-med&repo=lightning-admin-angular&type=fork&count=true&size=small"
        frameborder="0" scrolling="0" width="80px" height="20px"></iframe>

      <iframe
        src="https://ghbtns.com/github-btn.html?user=azouaoui-med&repo=lightning-admin-angular&type=star&count=true&size=small"
        frameborder="0" scrolling="0" width="80px" height="20px"></iframe>
    </div>


  </div>

</div>