import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-add-unit',
  templateUrl: './add-unit.component.html',
  styleUrls: ['./add-unit.component.scss']
})
export class AddUnitComponent implements OnInit {

  form = new FormGroup({});
  submitted = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private restService: RestService) {

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      label: ['', [Validators.required, Validators.minLength(2)]],
      description: ['', [Validators.required]],
    });
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.restService.add('units', this.form.value)
      .subscribe(
        res => {
          this.router.navigate(['units']);
        },
        error => {
          console.log(error)
        });
  }

}
