import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-edit-commodity',
  templateUrl: './edit-commodity.component.html',
  styleUrls: ['./edit-commodity.component.scss']
})
export class EditCommodityComponent implements OnInit {

  commodityId: any;
  categoryId: any;
  commodity: any;
  form = new FormGroup({});
  submitted = false;
  iconSrc = '';
  iconData: any;


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private restService: RestService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService) {
    this.categoryId = this.activatedRoute.snapshot.paramMap.get('categoryId');
    this.commodityId = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.getCommodity();

    this.form = this.formBuilder.group({
      category: [this.categoryId],
      id: [''],
      label: ['', [Validators.required, Validators.minLength(2)]],
      description: ['', [Validators.required]],
      disOrd: [''],
      languages: this.formBuilder.array([]),
    });
  }

  
  languages(): FormArray {
    return this.form.get("languages") as FormArray
  }

  addLanguage() {
    this.languages().push(this.newLanguage());
  }

  newLanguage(): FormGroup {
    return this.formBuilder.group({
      key: '',
      value: ''
    })
  }

  removeLanguage(i: number) {
    this.languages().removeAt(i);
  }


  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.restService.update(`categories/${this.categoryId}/commodities/${this.commodityId}`, this.form.value)
      .subscribe(
        res => {
          if (res.status_code == 200) {
            this.gotoCommodities()
            
            this.toastr.success('Commodity updated successfully.', 'Success');
          } else {
            this.toastr.error(res.status_message, 'Error');
          }
        },
        error => {
          this.toastr.error(error.error, 'Error');
        }
      );
    
    if (this.iconSrc) this.uploadFile('icon');
    
  }

  gotoCommodities(): void {
    this.router.navigate([`commodities/${this.categoryId}`]);
  }

  uploadFile(type: string): void {
    if (this.iconData) {
      const formData = new FormData();
      formData.append("files", this.iconData);
      this.restService.upload(`categories/${this.commodityId}/${type}`, formData)
      .subscribe(
        res => {
          console.log(`${type} updated`);
        },
        error => {
          this.toastr.error(error.error, 'Error');
        }
      );
    }
  }

  onIconChange(event: any) {
    const reader = new FileReader();    
    if(event.target.files && event.target.files.length) {
      this.iconData = event.target.files[0];
      reader.readAsDataURL(this.iconData);    
      reader.onload = () => {
        this.iconSrc = reader.result as string;
      };   
    }
  }

  getCommodity(): void {
    this.restService.get(`categories/commodities/`, this.commodityId).subscribe(res => {
      this.commodity = res.result;
      this.form.patchValue({
        category: this.categoryId, 
        id: this.commodity.id, 
        label: this.commodity.label,
        description: this.commodity.description,
        disOrd: this.commodity.disOrd,
        languages: []
      })
      if (this.commodity.languages2.length == 0) {
        this.languages().push(this.newLanguage());
      } else {
        this.commodity.languages2.forEach((language: any) => {
          this.languages().push(
            this.formBuilder.group(language)
          );  
        });
      }
      this.iconSrc = this.commodity.iconUrl;
    }, error => {
      console.log(error);
    })
  }
}
