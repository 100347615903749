<div>
    <div class="row mb-4">

        <div class="col-md-12">
            <div class="card border-0 rounded-0">
                <div class="card-title mb-1 p-3">
                    <div class="row">
                        <div class="col-md-10">
                            <h5>Categories</h5>
                        </div>
                        <div class="col-md-2 text-right">
                            <button (click)="addCategory()" class="btn btn-primary"><i class="fa fa-plus"></i>
                                Add</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive-md">
                        <table class="table table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Label</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Image</th>
                                    <th scope="col">Icon</th>
                                    <th scope="col">Commodities</th>
                                    <th scope="col">Languages</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let category of categories.data;">
                                    <td scope="row">{{ category.id }}</td>
                                    <td>{{ category.label }}</td>
                                    <td>{{ category.description }}</td>
                                    <td> <img *ngIf="category.imageUrl" width="75" src="{{ category.imageUrl }}"
                                            alt="{{ category.label }}">
                                    </td>
                                    <td> <img *ngIf="category.iconUrl" width="75" src="{{ category.iconUrl }}"
                                            alt="{{ category.label }}">
                                    </td>
                                    <td>
                                        <a class="btn btn-secondary btn-sm mr-2" (click)="addCommodity(category.id)">
                                            <i class="fa fa-plus"></i></a>
                                        <a class="btn btn-secondary btn-sm" *ngIf="category.commodities.length"
                                            (click)="gotoCommodities(category.id)">{{
                                            category.commodities.length ?
                                            category.commodities.length : '-' }}</a>

                                    </td>
                                    <td>
                                        <span *ngFor="let language of category.languages | keyvalue; let isLast=last">
                                            {{ language.value }} {{isLast ? '' : ', '}}
                                        </span>
                                    </td>
                                    <td>
                                        <a class="btn btn-sm btn-outline-lightning rounded-0 mr-2"
                                            (click)="onEdit(category.id)">
                                            <i class="far fa-edit"></i>
                                        </a>
                                        <a class="btn btn-sm btn-outline-lightning rounded-0 d-none"
                                            (click)="onDelete(category.id)">
                                            <i class="far fa-trash-alt"></i>
                                        </a>
                                    </td>
                                </tr>
                                <tr *ngIf="categories.data.length == 0">
                                    <td colspan="8" align="center">No Records</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>