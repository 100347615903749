import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  constructor(private rest: RestService, private router: Router) { }
  categories: any = [];

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories(): void {
    this.rest.getAll('categories?start=0&length=100').subscribe((res: any) => {
      this.categories = res.result;
    });
  }

  addCategory(): void {
    this.router.navigate(['/categories/add']);
  }

  gotoCommodities(categoryId: any): void {
    this.router.navigate(['/commodities/' + categoryId])
  }

  onEdit(categoryId: number): void {
    this.router.navigate(['/categories/edit/' + categoryId])
  }

  onDelete(categoryId: number): void {

  }
  
  addCommodity(categoryId: number): void {
    this.router.navigate([`/commodities/${categoryId}/add`]);
  }


}
