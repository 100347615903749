import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  endpoint = environment.baseUrl + 'public/';

  private currentUserSubject = new BehaviorSubject('');
  public currentUser = new Observable();

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser') || '{}'));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  login(loginuser: string, password: string) {
    return this.http.post<any>(this.endpoint + 'login?loginuser=' + loginuser + '&password=' + password, '')
      .pipe(map(res => {
        if (res && res.status_code == 200) {
          localStorage.setItem('currentUser', JSON.stringify(res.result));
          this.currentUserSubject.next(res.result);
          return res.result;
        }
      }));
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next('');
  }
}
