import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-add-commodity',
  templateUrl: './add-commodity.component.html',
  styleUrls: ['./add-commodity.component.scss']
})
export class AddCommodityComponent implements OnInit {

  form = new FormGroup({});
  categoryId: any;
  commodityId: any;
  submitted = false;
  languagesArr = [];
  iconSrc = '';
  iconData: any;

  constructor(private formBuilder: FormBuilder, private router: Router, private restService: RestService, private toastr: ToastrService, private activatedRoute: ActivatedRoute) {
    this.categoryId = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.getLanguages();

    this.form = this.formBuilder.group({
      category: this.categoryId,
      label: ['', [Validators.required, Validators.minLength(2)]],
      description: ['', [Validators.required]],
      disOrd: [''],
      languages: this.formBuilder.array([this.formBuilder.group({
        key: '',
        value: ''
      })]),
    });
    this.newLanguage();
  }


  languages(): FormArray {
    return this.form.get("languages") as FormArray
  }

  addLanguage() {
    this.languages().push(this.newLanguage());
  }

  newLanguage(): FormGroup {
    return this.formBuilder.group({
      key: '',
      value: ''
    })
  }

  removeLanguage(i: number) {
    this.languages().removeAt(i);
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    } 
     
    this.restService.add(`categories/${this.categoryId}/commodities`, this.form.value)
      .subscribe(
        res => {
          this.commodityId = res.result.id;
          if (this.iconSrc) this.updateFile('icon');
          this.gotoCommodities()
          this.toastr.success('Commodity added successfully.', 'Success');
        },
        error => {
          console.log(error)
        });
  }

  getLanguages(): void {
    this.restService.getLanguages('public/language').subscribe((res: any) => {
      this.languagesArr = res.result;
    });
  }

  
  gotoCommodities(): void {
    this.router.navigate([`commodities/${this.categoryId}`]);
  }

  updateFile(type: string): void {
    if (this.iconData) {
      const formData = new FormData();
      formData.append("files", this.iconData);
      this.restService.update(`categories/${this.categoryId}/commodities/${this.commodityId}`, formData)
      .subscribe(
        res => {
          console.log(`${type} updated`);
        },
        error => {
          this.toastr.error(error.error, 'Error');
        }
      );
    }
  }

  onIconChange(event: any) {
    const reader = new FileReader();    
    if(event.target.files && event.target.files.length) {
      this.iconData = event.target.files[0];
      reader.readAsDataURL(this.iconData);
      reader.onload = () => {
        this.iconSrc = reader.result as string;
      };   
    }
  }

}
