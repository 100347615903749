import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/internal/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RestService {
  currentUser: any;

  endpoint = environment.baseUrl + 'admin/';

  constructor(private http: HttpClient, private authService: AuthService, private toastr: ToastrService) {
    this.authService.currentUser.subscribe(user => this.currentUser = user);
  }

  get(url: string, id: any): Observable<any> {
    const options = {
      headers: new HttpHeaders().set('Authorization', this.currentUser.auth_token)
    }
    return this.http.get(this.endpoint + url + id, options).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  getAll(url: string): Observable<any> {
    const options = {
      headers: new HttpHeaders().set('Authorization', this.currentUser.auth_token)
    }
    return this.http.get(this.endpoint + url, options).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  getLanguages(url: string): Observable<any> {
    const options = {
      headers: new HttpHeaders().set('Authorization', this.currentUser.auth_token)
    }
    return this.http.get(environment.baseUrl+url, options).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  add(url: string, data: any): Observable<any> {
    const options = {
      headers: new HttpHeaders().set('Authorization', this.currentUser.auth_token)
    }
    return this.http.post(this.endpoint + url, data, options).pipe(
      catchError(this.handleError)
    );
  }

  update(url: string, data: any): Observable<any> {
    const options = {
      headers: new HttpHeaders().set('Authorization', this.currentUser.auth_token)
    }
    return this.http.post(this.endpoint + url, data, options).pipe(
      catchError(this.handleError)
    );
  }
  
  upload(url: string, data: any): Observable<any> {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', this.currentUser.auth_token)
    }
    return this.http.post(this.endpoint + url, data, options).pipe(
      catchError(this.handleError)
    );
  }

  delete(url: string, id: string): Observable<any> {
    return this.http.delete(this.endpoint + url + id).pipe(
      catchError(this.handleError)
    );
  }
  
  reports(url: string, data: any): Observable<any> {
    const options = {
      headers: new HttpHeaders().set('Authorization', this.currentUser.auth_token)
    }
    return this.http.post(this.endpoint + url, data, options).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  handleError(error: HttpErrorResponse): any {
    return throwError(error.error);
  }
}
