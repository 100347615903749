<nav class="navbar navbar-expand-md fixed-top navbar-dark flex-fill">
  <!-- <button class="sidebar-toggler ml-4" type="button">
    <i class="fa fa-navicon"></i>
  </button> -->
  <!-- header -->
  <div class="navbar-header pl-2 pr-2 ml-3 text-center">
    <a class="navbar-brand" href="#">
      <!-- <i class="fa fa-flash mr-1"></i> -->
      <img _ngcontent-bai-c57="" src="http://mintocart.com/assets/images/logo.svg" alt="lightning logo" style="
    width: 160px;
">
    </a>
  </div>
  <!-- sidebar toggler -->
  <div class="sidebar-toggler ml-auto mr-3">
    <a class="btn nav-link" (click)="toggleSidebar()"><i class="fa fa-bars"></i></a>
  </div>
  <!-- right menu toggler -->
  <div class="nav-toggler-right mr-4 d-md-none">
    <button class="" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseBasic">
      <!-- <span class="navbar-toggler-icon"></span> -->
      <img src="./assets/img/user.jpg" class="img-fluid" alt="">
    </button>
    <span class="user-profile nav-alert bg-danger"></span>
  </div>
  <!-- left box -->
  <!-- <ul class="navbar-nav flex-fill mt-1 align-items-center left-nav">
    <li class="nav-item navicon">
      <a class="btn nav-link" (click)="toggleSidebarPin()"><i class="fa fa-bars"></i></a>
    </li>
  </ul> -->

  <!-- right menu -->
  <div class="collapse navbar-collapse right-nav" id="collapseBasic" [collapse]="isCollapsed">
    <ul class="navbar-nav ">
      <li class="nav-item">
        <a class="btn nav-link" (click)="logout()">
          <i class="fas fa-sign-out-alt"></i>
          <span class="link-text">Logout</span>
        </a>
      </li>
    </ul>
  </div>
</nav>