<div>
    <div class="row mb-4">
        <div class="col-md-12">
            <div class="card border-0 rounded-0">
                <div class="card-title mb-1 p-3">
                    <div class="row">
                        <div class="col-md-6">
                            <h5>Commodities</h5>
                        </div>
                        <div class="col-md-4">
                            <select name="category" [(ngModel)]="category" class="form-control"
                                (change)="getCommoditiesByCategory()">
                                <option value="">Select</option>
                                <option [ngValue]="category.id" *ngFor="let category of categories.data"
                                    [selected]="categoryId == category.id">{{
                                    category.label
                                    }}</option>
                            </select>
                        </div>
                        <div class="col-md-2 text-right">
                            <button (click)="addCommodity()" class="btn btn-primary"><i class="fa fa-plus"></i>
                                Add</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive-md">
                        <table class="table table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Label</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Icon</th>
                                    <th scope="col">Languages</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let commodity of commodities.data;">
                                    <td scope="row">{{ commodity.id }}</td>
                                    <td>{{ commodity.label }}</td>
                                    <td>{{ commodity.description }}</td>
                                    <td> <img *ngIf="commodity.iconUrl" width="75" src="{{ commodity.iconUrl }}"
                                            alt="{{ commodity.label }}">
                                    </td>
                                    <td>
                                        <span *ngFor="let language of commodity.languages | keyvalue; let isLast=last">
                                            {{ language.value }} {{isLast ? '' : ', '}}
                                        </span>
                                    </td>
                                    <td>
                                        <a class="btn btn-sm btn-outline-lightning rounded-0 mr-2"
                                            (click)="onEdit(commodity.id)">
                                            <i class="far fa-edit"></i>
                                        </a>
                                        <a class="btn btn-sm btn-outline-lightning rounded-0 d-none"
                                            (click)="onDelete(commodity.id)">
                                            <i class="far fa-trash-alt"></i>
                                        </a>
                                    </td>
                                </tr>
                                <tr *ngIf="commodities.data.length == 0">
                                    <td colspan="6" align="center">No Records</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>