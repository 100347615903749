<!--The content below is only a placeholder and can be replaced.-->
<div class="wrapper default-theme align-items-stretch" [ngClass]="getClasses()">
  <app-navbar *ngIf=currentUser.auth_token></app-navbar>
  <main>
    <app-sidebar *ngIf=currentUser.auth_token></app-sidebar>
    <div class="pages container-fluid pt-4 pb-4 pl-4 pr-4 ">
      <router-outlet></router-outlet>
    </div>
    <div class="overlay" (click)="toggleSidebar()"></div>
  </main>
</div>