import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = new FormGroup({});
  submitted = false;
  currentUser: any;

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router) {
    this.authService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {

    if (this.currentUser.auth_token) {
      this.router.navigate(['categories']);
    }

    this.loginForm = this.formBuilder.group({
      loginuser: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.authService.login(this.f.loginuser.value, this.f.password.value)
      .subscribe(
        res => {
          this.router.navigate(['categories']);
        },
        error => {
          console.log(error)
        });
  }

}
