<div class="row d-flex justify-content-center align-items-center h-100">
    <div class="col-md-6 col-lg-4 m-auto">
        <div class="card border-0 rounded-0 h-100">
            <div class="card-title mb-1 p-3 text-center">
                <h5>Login</h5>
            </div>
            <div class="card-body">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

                    <div class="form-group">
                        <label>Email</label>
                        <input type="text" formControlName="loginuser" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.loginuser.errors }" />
                        <div *ngIf="submitted && f.loginuser.errors" class="invalid-feedback">
                            <div *ngIf="f.loginuser.errors.required">Email is required</div>
                            <div *ngIf="f.loginuser.errors.loginuser">Email must be a valid email address</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Password</label>
                        <input type="password" formControlName="password" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                            <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-primary">Login</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>