<div>
    <div class="row mb-4">

        <div class="col-md-12">
            <div class="card border-0 rounded-0">
                <div class="card-title mb-1 p-3">
                    <div class="row">
                        <div class="col-md-6">
                            <h5>Deals</h5>
                        </div>
                        <div class="col-md-3">
                            <select name="stat" [(ngModel)]="stat" class="form-control"
                                (change)="getCommoditiesByFilter()">
                                <option value="">Select</option>
                                <option value="STARTED">STARTED</option>
                                <option value="CREATED">CREATED</option>
                                <option value="ENDED">ENDED</option>
                                <option value="CANCELED">CANCELED</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <select name="locationType" [(ngModel)]="locationType" class="form-control"
                                (change)="getCommoditiesByFilter()">
                                <option value="">Select</option>
                                <option value="CART">CART</option>
                                <option value="SHOP">SHOP</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive-md">
                        <table class="table table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Image</th>
                                    <th scope="col">Details</th>
                                    <th scope="col">Address</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let deal of deals.data;">
                                    <td scope="row">{{ deal.id }}</td>
                                    <td>
                                        <div *ngFor="let image of deal.dealImages; let i=index">
                                            <a (click)="imagePopup(image.url)"><img [src]="image.url" width="50" /></a>
                                        </div>
                                    </td>
                                    <td>
                                        <div>Commodity: {{ deal.commodity.label }}</div>
                                        <div>Rate: {{ deal.dealRate.label }}</div>
                                        <div>Quantity: {{ deal.quantity }}</div>
                                        <div>Start Date: {{ convetToDate(deal.startTime) }}</div>
                                        <div>End Date: {{ convetToDate(deal.endTime)}}</div>
                                    </td>
                                    <td width="200">{{deal.dealAddress===null || deal.dealAddress.formatted_address === null ? '':deal.dealAddress.formatted_address}}</td>
                                    <td>{{ deal.dealStatus ? 'Active' : 'Inactive' }}</td>
                                    <td>
                                        <a class="btn btn-sm mr-2 btn-secondary"
                                            [ngClass]="{'btn-success': deal.dealStatus == true}"
                                            (click)="updateStatus(deal.id, deal.dealStatus)">
                                            {{ !deal.dealStatus ? 'Activate' : 'Deactivate' }}
                                        </a>
                                    </td>
                                </tr>
                                <tr *ngIf="deals.length == 0">
                                    <td colspan="7" align="center">No Records</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="myModal" class="modal" [ngStyle]="{ 'display': showModel }">
        <span class="close" (click)="closePopup()">&times;</span>
        <img class="modal-content" id="img01" src="{{imageUrl}}">
        <div id="caption"></div>
    </div>
</div>