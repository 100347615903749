import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.scss']
})
export class DealsComponent implements OnInit {

  private _album = [];
  constructor(private restService: RestService, private router: Router, private toastr: ToastrService
    ) { }
  deals: any = [];
  stat = '';
  locationType = '';
  imageUrl = '';
  showModel = 'none';
  ngOnInit(): void {
    this.getDeals();
  }

  getDeals(): void {
    let filter = '&stat='+ this.stat;  
    filter += '&locationType='+ this.locationType;
    this.restService.getAll('deals?start=0&length=100'+ filter).subscribe((res: any) => {
      this.deals = res.result;
    });
  }

  addDeal(): void {
    this.router.navigate(['/deals/add']);
  }

  updateStatus(dealId: number, status: number): void {
    status = !status ? 1 : 0;
    this.restService
      .get(`deals/${dealId}/status/`, status)
      .subscribe(
        (res) => {
          let message = status ? 'Activated' : 'Deactivated';
          this.toastr.success(`Deal ${message} successfully.`, 'Success');          
          this.getDeals();
        },
        (error) => {
          this.toastr.error(error.error, "Error");
        }
      );
  }

  getCommoditiesByFilter(): void {
    this.getDeals();
  } 

  convetToDate(string: string)  {
    return new Date(string).toLocaleString();
  }

  imagePopup(url: string) {
    this.imageUrl = url;
    this.showModel = 'block';
  }
  
  closePopup()
  {
    this.showModel = 'none';
  }
}
