import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-edit-unit',
  templateUrl: './edit-unit.component.html',
  styleUrls: ['./edit-unit.component.scss']
})
export class EditUnitComponent implements OnInit {

  form = new FormGroup({});
  submitted = false;
  unitId:any;
  unit:any;


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private restService: RestService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService) {
    this.unitId = this.activatedRoute.snapshot.paramMap.get('id');
  }


  ngOnInit(): void {
    this.getUnit();

    this.form = this.formBuilder.group({
      id: [''],
      label: ['', [Validators.required, Validators.minLength(2)]],
      description: ['', [Validators.required]],
    });
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.restService.update('units/', this.form.value)
      .subscribe(
        res => {
          this.router.navigate(['units']);
          this.toastr.success('Unit updated successfully.', 'Success');
        },
        error => {
          this.toastr.error(error.error, 'Error');
        }
      );
  }
  
  getUnit(): void {
    this.restService.get('units/', this.unitId).subscribe(res => {
      this.unit = res.result;
      this.form.setValue({
        id: this.unit.id,
        label: this.unit.label,
        description: this.unit.description,
      })
    }, error => {
      console.log(error);
    })
  }
}
