import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { AddCategoryComponent } from './pages/categories/add-category/add-category.component';
import { AuthGuard } from './helpers/auth.guard';
import { UnitsComponent } from './pages/units/units.component';
import { AddUnitComponent } from './pages/units/add-unit/add-unit.component';
import { VendorsComponent } from './pages/vendors/vendors.component';
import { CommoditiesComponent } from './pages/commodities/commodities.component';
import { EditCategoryComponent } from './pages/categories/edit-category/edit-category.component';
import { EditUnitComponent } from './pages/units/edit-unit/edit-unit.component';
import { EditCommodityComponent } from './pages/commodities/edit-commodity/edit-commodity.component';
import { AddCommodityComponent } from './pages/commodities/add-commodity/add-commodity.component';
import { DealsComponent } from './pages/deals/deals.component';
import { ReportsComponent } from './pages/reports/reports.component';

const routes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'vendors', component: VendorsComponent, canActivate: [AuthGuard] },
  { path: 'categories', component: CategoriesComponent, canActivate: [AuthGuard] },
  { path: 'categories/add', component: AddCategoryComponent, canActivate: [AuthGuard] },
  { path: 'categories/edit/:id', component: EditCategoryComponent, canActivate: [AuthGuard] },
  { path: 'commodities/:id', component: CommoditiesComponent, canActivate: [AuthGuard] },
  { path: 'commodities/:id/add', component: AddCommodityComponent, canActivate: [AuthGuard] },
  { path: 'commodities/:categoryId/edit/:id', component: EditCommodityComponent, canActivate: [AuthGuard] },
  { path: 'units', component: UnitsComponent, canActivate: [AuthGuard] },
  { path: 'units/add', component: AddUnitComponent, canActivate: [AuthGuard] },
  { path: 'units/edit/:id', component: EditUnitComponent, canActivate: [AuthGuard] },
  { path: 'deals', component: DealsComponent, canActivate: [AuthGuard] },
  { path: 'reports', component: ReportsComponent , canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
