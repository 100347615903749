<div>
    <div class="row mb-4">

        <div class="col-md-12">
            <div class="card border-0 rounded-0">
                <div class="card-title mb-1 p-3">
                    <div class="row">
                        <div class="col-md-10">
                            <h5>Units</h5>
                        </div>
                        <div class="col-md-2 text-right">
                            <button (click)="addUnit()" class="btn btn-primary"><i class="fa fa-plus"></i>
                                Add</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive-md">
                        <table class="table table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Label</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let unit of units.data;">
                                    <td scope="row">{{ unit.id }}</td>
                                    <td>{{ unit.label }}</td>
                                    <td>{{ unit.description }}</td>
                                    <td>
                                        <a class="btn btn-sm btn-outline-lightning rounded-0 mr-2"
                                            (click)="onEdit(unit.id)">
                                            <i class=" far fa-edit"></i>
                                        </a>
                                        <a class="btn btn-sm btn-outline-lightning rounded-0 d-none"
                                            (click)="onDelete(unit.id)">
                                            <i class="far fa-trash-alt"></i>
                                        </a>
                                    </td>
                                </tr>
                                <tr *ngIf="units.data.length == 0">
                                    <td colspan="4" align="center">No Records</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>