<div>
    <div class="row mb-4">

        <div class="col-md-12">
            <div class="card border-0 rounded-0">
                <div class="card-title mb-1 p-3">
                    <h5>Reports</h5>
                    <div class="row form-group">
                        <div class="col-sm-6 col-md-3">
                            <select name="category" [(ngModel)]="category" multiple="multiple" class="form-control"
                                (change)="reportFilters()">
                                <option value="">Select Category</option>
                                <option [ngValue]="category.id" *ngFor="let category of categories.data">{{
                                    category.label
                                    }}</option>
                            </select>
                        </div>
                        <div class="col-sm-6 col-md-3">
                            <select name="commodity" [(ngModel)]="commodity" multiple="multiple" class="form-control"
                                (change)="reportFilters()">
                                <option value="">Select Commodity</option>
                                <option [ngValue]="commodity.id" *ngFor="let commodity of commodities">{{
                                    commodity.label
                                    }}</option>
                            </select>
                        </div>

                        <div class="col-sm-6 col-md-3">
                            <select name="vendor" [(ngModel)]="vendorId" multiple="multiple" class="form-control"
                                (change)="reportFilters()">
                                <option value="">Select Vendor</option>
                                <option [ngValue]="vendor.id" *ngFor="let vendor of vendors">{{
                                    vendor.name
                                    }}</option>
                            </select>
                        </div>

                        <div class="col-sm-6 col-md-3">
                            <select name="stat" [(ngModel)]="stat" class="form-control" (change)="reportFilters()">
                                <option value="">Select Status</option>
                                <option value="STARTED">STARTED</option>
                                <option value="CREATED">CREATED</option>
                                <option value="ENDED">ENDED</option>
                                <option value="CANCELED">CANCELED</option>
                            </select>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-sm-6 col-md-3">
                            <select name="locationType" [(ngModel)]="locationType" class="form-control"
                                (change)="reportFilters()">
                                <option value="">Select Location Type</option>
                                <option value="CART">CART</option>
                                <option value="SHOP">SHOP</option>
                            </select>
                        </div>

                        <div class="col-sm-6 col-md-3">
                            <select name="dealStatus" [(ngModel)]="dealStatus" class="form-control"
                                (change)="reportFilters()">
                                <option value="">Select Deal Status</option>
                                <option value="true">Active</option>
                                <option value="false">Inactive</option>
                            </select>
                        </div>
                        <div class="col-sm-6 col-md-3 form-group">
                            <input type="text" name="fromName" placeholder="Date Range" [(ngModel)]="dateTime"
                                class="form-control" bsDaterangepicker [bsConfig]="{ isAnimated: true,rangeInputFormat : 'DD-MM-YYYY', dateInputFormat: 'DD-MM-YYYY' }">
                        </div>
                        <div class="col-sm-6 col-md-3">
                            <button (click)="reportFilters()" class="btn btn-primary">Filter</button>
                            <button (click)="resetFilters()" class="btn btn-secondary ml-2">Clear</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive-md">
                        <table class="table table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Image</th>
                                    <th scope="col">Details</th>
                                    <th scope="col">Vendor</th>
                                    <th scope="col">Address</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="reports.data">
                                <tr *ngFor="let deal of reports.data;">
                                    <td scope="row">{{ deal.id }}</td>

                                    <td>
                                        <div *ngFor="let image of deal.dealImages; let i=index">
                                            <a (click)="imagePopup(image.url)"><img [src]="image.url" width="50" /></a>
                                        </div>
                                    </td>
                                    <td>
                                        <div>Commodity: {{ deal.commodity.label }}</div>
                                        <div>Rate: {{ deal.price}}</div>
                                        <div>Quantity: {{ deal.quantity }}{{deal.unit.label}}</div>
                                        <div>Start Date: {{ convetToDate(deal.startTime)| date:'dd-MM-yyyy, h:mm:ss a'}}</div>
                                        <div>End Date: {{ convetToDate(deal.endTime) | date:'dd-MM-yyyy, h:mm:ss a'}}</div>
                                    </td>
                                    <td>
                                        <div>{{ deal.vendor.firstName }}</div>
                                        <div>{{ deal.vendor.phone }}</div>
                                    </td>
                                    <td width="200">{{deal.dealAddress===null || deal.dealAddress.formatted_address === null ? '':deal.dealAddress.formatted_address}}</td>
                                    <td>{{ deal.dealStatus ? 'Active' : 'Inactive' }}</td>

                                </tr>
                            </tbody>
                            <tr *ngIf="reports.length == 0">
                                <td colspan="7" align="center">No Records</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="myModal" class="modal" [ngStyle]="{ 'display': showModel }">
        <span class="close" (click)="closePopup()">&times;</span>
        <img class="modal-content" id="img01" src="{{imageUrl}}">
        <div id="caption"></div>
    </div>
</div>