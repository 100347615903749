<div>
    <div class="row">
        <div class="col-md-6 mb-4 ">
            <div class="card border-0 rounded-0">
                <div class="card-title mb-1 p-3">
                    <h5>Edit Category</h5>
                </div>
                <div class="card-body">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label>Label</label>
                            <input type="text" formControlName="label" class="form-control  rounded-0"
                                [ngClass]="{ 'is-invalid': submitted && f.label.errors }">
                            <div *ngIf="submitted && f.label.errors" class="invalid-feedback">
                                <div *ngIf="f.label.errors.required">Label is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Description</label>
                            <textarea formControlName="description" class="form-control rounded-0" rows="3"
                                [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                            <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                <div *ngIf="f.description.errors.required">Description is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-lightning rounded-0 mb-2 mr-2">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>